import gsap from "gsap";
import { DrawSVGPlugin, MorphSVGPlugin, MotionPathPlugin } from "gsap/all";
import DefaultBlock from "/scripts/default/block";
import { watchPreferredMotion } from "../../../../utils/helpers/watchPreferredMotion";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(
	DrawSVGPlugin,
	MorphSVGPlugin,
	MotionPathPlugin,
	ScrollTrigger
);

export default class HomeTools extends DefaultBlock {
	init() {
		const el = {
			// Scroll
			scrollRow: this.block.querySelector("#home-tools-scroll"),
			scrollCurve: this.block.querySelector("#home-tools-scroll-curve"),
			scrollBall: this.block.querySelector("#home-tools-scroll-ball"),
			scrollPath: this.block.querySelector("#home-tools-scroll-path"),
			scrollMask: this.block.querySelector("#home-tools-scroll-mask"),

			// SVG
			svgRow: this.block.querySelector("#home-tools-svg"),
			svgSquare: this.block.querySelectorAll(".home-tools-svg-square"),
			svgSquareLarge: this.block.querySelector("#home-tools-svg-square-large"),
			svgCircle: this.block.querySelectorAll(".home-tools-svg-circle"),
			svgCircleSmall: this.block.querySelector(".home-tools-svg-circle-small"),

			// Text
			textRow: this.block.querySelector("#home-tools-text"),
			textCircleWrapper: this.block.querySelector(
				"#home-tools-text-circle-wrapper"
			),
			textCircle: this.block.querySelector("#home-tools-text-circle"),
			textTe: this.block.querySelector("#home-tools-text-te"),
			textXt: this.block.querySelector("#home-tools-text-xt"),
			textSquareWrapper: this.block.querySelector(
				"#home-tools-text-square-wrapper"
			),
			textSquare: this.block.querySelector("#home-tools-text-square"),

			// UI
			uiRow: this.block.querySelector("#home-tools-ui"),
			uiSquare: this.block.querySelectorAll(".home-tools-ui-square"),
		};
		this.el = el;

		this.initIndexTools();
	}

	setIndexTools() {
		watchPreferredMotion(() => {
			// Scroll
			this.setIndexToolsScroll();

			// Text
			this.setIndexToolsText();

			// UI
			this.setIndexToolsUi();
		});
	}

	playIndexTools() {
		watchPreferredMotion(() => {
			// Scroll
			this.playIndexToolsScroll();

			// SVG
			this.playIndexToolsSvg();

			// Text
			this.playIndexToolsText();

			// UI
			this.playIndexToolsUi();
		});
	}

	/*
	 * Scroll
	 */
	setIndexToolsScroll() {
		gsap.set(this.el.scrollMask, {
			drawSVG: "0% 100%",
		});
	}

	playIndexToolsScroll() {
		const tl = gsap.timeline({
			defaults: {
				duration: 1.25,
				ease: "power3.inOut",
			},
			scrollTrigger: {
				trigger: this.el.scrollRow,
				start: "top 75%",
				end: "top 50%",
				once: true,
			},
		});

		tl.to(this.el.scrollBall, {
			motionPath: {
				path: this.el.scrollPath,
				align: this.el.scrollPath,
				alignOrigin: [0.5, 0.5],
			},
		}).to(
			this.el.scrollMask,
			{
				drawSVG: "85% 100%",
			},
			"<"
		);
	}

	/*
	 * SVG
	 */
	playIndexToolsSvg() {
		const tl = gsap.timeline({
			defaults: {
				duration: 1.25,
				ease: "power3.inOut",
			},
			scrollTrigger: {
				trigger: this.el.svgRow,
				start: "top 75%",
				end: "top 50%",
			},
		});

		tl.to(
			this.el.svgCircle,
			{
				morphSVG: this.el.svgSquareLarge,
			},
			0
		).fromTo(
			this.el.svgCircleSmall,
			{
				transformOrigin: "80% 20%",
				scale: 0.3,
			},
			{
				scale: 1,
			},
			0
		);
	}

	/*
	 * Text
	 */
	setIndexToolsText() {
		gsap.set(this.el.textCircleWrapper, {
			transformOrigin: "0% 0%",
		});

		gsap.set(this.el.textCircle, {
			transformOrigin: "50% 50%",
		});

		gsap.set(this.el.textSquareWrapper, {
			transformOrigin: "100% 100%",
		});

		gsap.set(this.el.textSquare, {
			transformOrigin: "50% 50%",
			rotateX: 180,
		});

		gsap.set(this.el.textTe, {
			transformOrigin: "50% 50%",
			rotateX: 540,
		});
	}

	playIndexToolsText() {
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: this.el.textRow,
				start: "top 75%",
				end: "top 50%",
				once: true,
			},
		});

		// Rotate shapes and 'Te'
		tl.to([this.el.textCircleWrapper, this.el.textSquareWrapper], {
			rotate: 180,
			duration: 1,
			ease: "Power4.inOut",
		});
		tl.to(
			this.el.textTe,
			{
				rotateX: 0,
				duration: 1.5,
				ease: "Power4.inOut",
			},
			"<"
		);

		// Rotate 'xt' and square
		tl.to(
			this.el.textXt,
			{
				rotateX: 180,
				duration: 0.75,
				ease: "Power4.inOut",
			},
			"-=0.5"
		);
		tl.to(
			this.el.textSquare,
			{
				rotateX: 0,
				duration: 0.75,
				ease: "Power4.inOut",
			},
			"<"
		);

		// Rotate 'xt' and square back again
		tl.to(this.el.textXt, {
			rotateX: 0,
			duration: 0.75,
			ease: "Power4.inOut",
		});
		tl.to(
			this.el.textSquare,
			{
				rotate: 180,
				duration: 0.75,
				ease: "Power4.inOut",
			},
			"<"
		);
	}

	/*
	 * UI
	 */
	setIndexToolsUi() {
		gsap.set(
			[
				this.el.uiSquare[0],
				this.el.uiSquare[2],
				this.el.uiSquare[4],
				this.el.uiSquare[6],
				this.el.uiSquare[8],
			],
			{
				opacity: 0,
			}
		);
	}

	playIndexToolsUi() {
		const itemTiming = "<+0.1";
		const box2 = this.el.uiSquare[1];
		const box4 = this.el.uiSquare[3];
		const box5 = this.el.uiSquare[4];
		const box6 = this.el.uiSquare[5];
		const box8 = this.el.uiSquare[7];

		const tl = gsap
			.timeline({
				defaults: {
					duration: 0.4,
					ease: "power3.inOut",
				},
				scrollTrigger: {
					trigger: this.el.uiRow,
					start: "top 75%",
					end: "top 50%",
					once: true,
				},
			})
			.to(box2, {
				yPercent: 100,
			})
			.to(
				box4,
				{
					xPercent: 100,
				},
				itemTiming
			)
			.to(
				box6,
				{
					xPercent: -100,
				},
				itemTiming
			)
			.to(
				box8,
				{
					yPercent: -100,
				},
				itemTiming
			)
			.set(box5, {
				opacity: 1,
			})
			.to(box8, {
				yPercent: "+=100",
				xPercent: "+=100",
			})
			.to(
				box6,
				{
					yPercent: "+=100",
					xPercent: "-=100",
				},
				itemTiming
			)
			.to(
				box4,
				{
					yPercent: "-=100",
					xPercent: "-=100",
				},
				itemTiming
			)
			.to(
				box2,
				{
					yPercent: "-=100",
					xPercent: "+=100",
				},
				itemTiming
			);
	}

	initIndexTools() {
		this.setIndexTools();
		this.playIndexTools();
	}
}
