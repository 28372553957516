import DefaultFactory from "/scripts/default/factory";
import HomeHero from "/components/home/hero";
import HomeIntro from "/components/home/intro";
import HomeAnimate from "/components/home/animate";
import HomeTools from "/components/home/tools";
/*
	Individual page factories follow the same principles as
	the global factory in /public/scripts/index.js but contain
	blocks that should only be checked for on a single page
*/
class HomeFactory extends DefaultFactory {
	createBlock(blockName, block) {
		switch (blockName) {
			case "home-hero":
				return new HomeHero(block);
			case "home-intro":
				return new HomeIntro(block);
			case "home-animate":
				return new HomeAnimate(block);
			case "home-tools":
				return new HomeTools(block);
			default:
				return null;
		}
	}
}

new HomeFactory();
