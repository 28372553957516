import gsap from "gsap";
import { DrawSVGPlugin, ScrollTrigger, SplitText } from "gsap/all";
import DefaultBlock from "/scripts/default/block";

// Register Plugins
gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger, SplitText);

export default class HomeIntro extends DefaultBlock {
	// Define variables
	init() {
		this.selector = gsap.utils.selector(this.block);

		const DOM = {
			stem: this.selector(".stem"),
			flower: this.selector(".flower"),
			highlightSections: this.selector(".home-intro__highlight"),
			flairOne: this.selector(".home-intro__flair-one"),
			flairTwo: this.selector(".home-intro__flair-two"),
			flairThree: this.selector(".home-intro__flair-three"),
		};
		this.DOM = DOM;

		this.createTimelines();
	}

	staggerWord(chars) {
		return gsap.to(chars, {
			color: "#0ae448",
			ease: "power3.inOut",
			duration: 0.8,
			stagger: {
				each: 0.075,
				repeat: 1,
				yoyo: true,
			},
		});
	}

	wordOne() {
		const wordWrap = this.DOM.highlightSections[0];
		const words = wordWrap.querySelectorAll(".home-intro__highlight-word");
		const wordOne = new SplitText(words[0], { type: "chars" });
		const wordTwo = new SplitText(words[1], { type: "chars" });

		const tl = gsap.timeline();

		gsap.set(this.DOM.stem, { drawSVG: "0" });
		gsap.set(this.DOM.flower, { scale: 0, transformOrigin: "50% 50%" });

		tl.add(this.staggerWord([wordOne.chars, wordTwo.chars]));

		tl.to(
			this.DOM.stem,
			{
				keyframes: [
					{ drawSVG: "0% 100%", ease: "power4.inOut", duration: 1 },
					{
						drawSVG: "100% 100%",
						ease: "power4.inOut",
						duration: 1,
						delay: 0.8,
					},
				],
			},
			0
		);

		tl.to(
			this.DOM.flower,
			{
				keyframes: [
					{ scale: 1, ease: "power2.out", duration: 0.6 },
					{
						scale: 0,
						ease: "power2.inOut",
						rotateZ: 360,
						duration: 0.6,
						delay: 1.2,
					},
				],
			},
			0.8
		);

		tl.fromTo(
			[this.DOM.flairOne, this.DOM.flairTwo, this.DOM.flairThree],
			{ y: 100, scale: 0, rotate: -180, transformOrigin: "50% 50%" },
			{
				y: 0,
				scale: 1,
				rotate: 0,
				ease: "back.out(1.3)",
				duration: 0.8,
				stagger: 0.2,
			},
			0.6
		);

		tl.to(
			[this.DOM.flairOne, this.DOM.flairTwo, this.DOM.flairThree],
			{
				autoAlpha: 0,
				y: -50,
				ease: "power2.in",
				duration: 0.6,
				stagger: 0.1,
			},
			"-=1.4"
		);

		return tl;
	}

	wordTwo() {
		const wordWrap = this.DOM.highlightSections[1];
		const words = wordWrap.querySelectorAll(".home-intro__highlight-word");
		const wordOne = new SplitText(words[0], { type: "chars" });
		const wordTwo = new SplitText(words[1], { type: "chars" });
		const wordThree = new SplitText(words[2], { type: "chars" });

		const tl = gsap.timeline();

		tl.add(this.staggerWord([wordOne.chars, wordTwo.chars, wordThree.chars])) +
			tl.to(
				[wordOne.chars, wordTwo.chars, wordThree.chars],
				{
					rotateX: 360,
					duration: 2,
					stagger: 0.075,
					ease: "power3.inOut",
				},
				0
			);

		return tl;
	}

	wordThree() {
		const wordWrap = this.DOM.highlightSections[2];
		const words = wordWrap.querySelectorAll(".home-intro__highlight-word");
		const wordOne = new SplitText(words[0], { type: "chars" });
		const wordTwo = new SplitText(words[1], { type: "chars" });

		const tl = gsap.timeline();

		tl.add(this.staggerWord([wordOne.chars, wordTwo.chars])) +
			tl.from(
				[wordOne.chars, wordTwo.chars],
				{
					opacity: 0,
					y: 100,
					duration: 1,
					scale: 0.6,
					stagger: 0.125,
					ease: "elastic.out(1, 0.4)",
				},
				0
			);

		return tl;
	}

	createTimelines() {
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: this.block,
				start: "top 60%",
				once: true,
			},
		});

		gsap.set(this.block, { autoAlpha: 1 });

		const mm = gsap.matchMedia();

		mm.add(
			["(prefers-reduced-motion: no-preference)", "(min-width: 1240px)"],
			() => {
				tl.add(this.wordOne());
				tl.add(this.wordTwo(), "-=1");
				tl.add(this.wordThree(), "-=.5");
			}
		);
	}
}
